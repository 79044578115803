import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const APPage = () => (
    <Layout>
    <SEO title="Become A Smile Stylist Ambassador | Ambassador Program"
     description="The Smile Stylist is looking for Brand Ambassadors who are currently making a positive impact on the planet and are in need of a new smile. "
      keywords="ambassador program" />
    <main className="site-content" role="main">
    <section className="has-image-right_partner _pad_bottom" id="main-white">
      <div className="container _pad">
        <div className="row">
          <div className="col-sm-12 wow animated fadeInRight">
            <div className="row">
              <div className="col-md-9 col-sm-12 wow animated fadeInRight">
                <h1 className="info__heading_small">BECOME A SMILE STYLIST AMBASSADOR</h1>

                <p className="info__info">We are looking for outgoing, passionate and motivated individuals who are currently<br />
                  using their social platform to help make a difference on our planet. Whether you are<br />
                  trying to raise awareness for a cause, are focused on saving the environment,<br />
                  are looking to educate others on the importance of health or are in some way trying to <br />
                  make a positive impact on our planet we want to hear from you!</p>
                <p className="info__info info__info_space">We will work with you to help empower your smile and use our platform<br />
                  to support you in any way we can. If you are interested in becoming a<br />
                  Smile Stylist Ambassador and would like more information please<br />
                  contact <a href="mailto:press@thesmilestylist.ca" className="info__email_red">press@thesmilestylist.ca</a>
                </p>
              </div>
              <div className="col-md-3 hidden-sm"></div>
            </div>
          </div>
        </div>
        <div className="row row__second">
          <div className="col-sm-12 col-md-4 wow animated fadeInRight">
            <img className="info__img-left" src={require("../images/desktop/partner-left-img.png")} alt="Smile Stylist Ambassador" />
          </div>
          <div className="col-sm-12 col-md-8 wow animated fadeInRight">
            <p className="info__heading_small">SMILE STYLIST: ACTOR/MODEL PROGRAM</p>
            <p className="info__info">Over the years we have partnered with a number of actors and models to help them
              achieve their dream smiles. This has ultimately had a significant impact in
              their lives and careers and has been a great way for us to grow our brand as well as meet some fascinating individuals.
              We are always looking for unique stories and cases of individuals who
              demonstrate similar values to ours who could use a little dental help to take their
              career to the next level.
            </p>
            <p className="info__info info__info_space">If you are interested in applying for our discounted actor/model program and would like more information<br />
              please contact
              <a href="mailto:press@thesmilestylist.ca" className="info__email_red">press@thesmilestylist.ca</a></p>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default APPage